.button {
    display: block;
    padding: 16px 20px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    outline: none;
    appearance: none;
    border: none;
    font-family: Kiroku-Sans-Black, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    font-size: 16px;
    line-height: 18px;

    background-color: #E5F7F2;
    color: var(--earth-one);
}

.button:hover {
    background-color: var(--earth-one);
    color: #fff;
    transition: 0.3s;
}

.button:disabled {
    background-color: var(--border-one);
    color: #fff;
}

.button.oceanDark {
    background-color: var(--ocean-one);
    border-radius: 6px;
    /* because adding a different colour border shows the inner border radius, which is border radius + border width*/
    border: 3px solid #fff;
    color: #fff;
}

.button.oceanDark:hover {
    border: 3px solid var(--ocean-three);
}


.button.earthDark {
    background-color: var(--earth-one);
    border-radius: 6px;
    border: 3px solid #fff;
    color: #fff;
}

.button.earthDark:hover {
    border: 3px solid var(--earth-three);
}