:root {
  --earth-one: #007A87;
  --earth-two: #15A2B1;
  --earth-three: #ECF9F5;

  --ocean-one: #266EF1;
  --ocean-two: #5890FF;
  --ocean-three: #E9F0FD;

  --fire-one: #FB592D;
  --fire-two: #FF7A00;
  --fire-three: #FEE3E1;
  --fire-four: #FFF8E6;

  --text-one: #152935;
  --text-two: #3C4043;

  --border-one: #DADCE0;

  --ui-one: #F2F2F7;
  --ui-two: #F6F7FA;
  --ui-three: #F8F9FA;
}

@font-face {
  font-family: 'Kiroku-Sans-Black';
  src: url('./fonts/KirokuSans.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Kiroku-Sans-Bold';
  src: url('./fonts/KirokuSans.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Kiroku-Sans-Medium';
  src: url('./fonts/KirokuSans.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Kiroku-Sans-Light';
  src: url('./fonts/KirokuSans.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

body {
  margin: 0;
  font-family: Kiroku-Sans-Medium, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--text-one);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
  color: var(--earth-one);
}

a:hover {
  color: var(--earth-two);
}

a:visited {
  color: var(--earth-one);
}

.headline {
  font-family: Kiroku-Sans-Black, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 28px;
  line-height: 32px;
  color: var(--text-one);
  margin-top: 0px;
}

.subtitle {
  font-family: Kiroku-Sans-Bold, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: var(--text-one);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}