.mainContainer {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.header {
    height: 70px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 25px;
    border-bottom: 1px solid var(--border-one);
}

.visit {
    font-family: Kiroku-Sans-Black, sans-serif;
    font-size: 16px;
    line-height: 22px;
}

.title {
    display: flow-root;
    width: 280px;
}

.logo {
    font-family: Kiroku-Sans-Black, sans-serif;
    font-size: 24px;
    line-height: 41px;
    color: var(--earth-one);
}

.logo>img {
    display: none;
}

@media (max-width: 600px) {
    .logo>span {
        display: none;
    }

    .logo>img {
        display: block;
    }

    .title {
        display: none;
    }
}

.appContainer {
    max-width: 375px;
    margin: 50px auto;
    text-align: center;
    flex: 1 0 auto;
    padding: 0 20px;
}

.appContainer button {
    width: 100%;
}

.startContent>div {
    margin-bottom: 50px;
}

.aboutSection {
    border-top: 1px solid var(--border-one);
}

.card {
    border: 1px solid var(--border-one);
    border-radius: 8px;
    padding: 44px 32px;
}

.card>img {
    width: 100%;
    margin: 0 auto;
}

.footer {
    flex-shrink: 0;
    border-top: 1px solid var(--border-one);
    color: var(--border-one);
    text-align: center;
}

.footer>div {
    margin: 15px auto;
}

.footer>div>* {
    margin: 0 20px;
}


.queryContainer>button {
    margin-bottom: 10px;
    color: var(--text-one);
    background-color: var(--ocean-three);
    font-family: Kiroku-Sans-Medium, sans-serif;
}

.queryContainer>button:hover {
    margin-bottom: 10px;
    color: #fff;
    background-color: var(--ocean-one);
}